import React, { createContext, useContext } from 'react';
import DH_Logo from "../assets/dirty_hands/DH_Logo_Classic_Grn+Gry.png";

const UserContext = createContext();

let navigation = [];

export const useUserContext = () => useContext(UserContext);
export const UserProvider = props => {
  const { user, profile, currentOrganization, loading, setLoading, setCurrentOrganization } = props.value;
  const allowedOrganizations = profile?.allowed_organizations ?? [];
  const currentOrg = allowedOrganizations.find((org) => org.id === currentOrganization);
  const currentPermissions = currentOrg?.permissions ?? [];

  navigation = [];

  const addNavigationItem = (item) => {
    if (!navigation.some(navItem => navItem.id === item.id)) {
      navigation.push(item);
    }
  };

  // Analytics routes
  if (currentPermissions.includes('fe__analytics__view') && profile?.user_analytics_active) {
    addNavigationItem({ name: 'Analytics', href: '/analytics', id: 'fe__analytics__view' });
  }
  if (currentPermissions.includes('embed__612__view')) {
    addNavigationItem({
      name: 'Dirty Hands',
      href: '/analytics/dirty-hands-merch',
      id: 'embed__612__view',
      subNavigation: [
        {
          name: 'Whole Foods Merch Insights',
          description: 'Impacts and performance for your brand at Whole Foods Market',
          href: '/analytics/dirty-hands-merch',
          id: 'embed__612__view',
          image: DH_Logo
        }
      ]
    });
  }

  if (currentPermissions.includes('fe__integrations__view')) {
    addNavigationItem({ name: 'Integrations', href: '/integrations', id: 'fe__integrations__view' });
  }
  if (currentPermissions.includes('fe__exports__view')) {
    addNavigationItem({ name: 'Destinations', href: '/destinations', id: 'fe__exports__view' });
  }

  return (
    <UserContext.Provider value={{
      user,
      profile,
      currentOrganization,
      loading,
      setLoading,
      setCurrentOrganization,
      allowedOrganizations,
      currentOrg,
      currentPermissions,
      allowedNavigation: navigation
    }}>
      {props.children}
    </UserContext.Provider>
  );
};
