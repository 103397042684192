import { useNavigate } from "react-router-dom";
import { getEmbeddedAnalyticsUrl } from '../../libs/fetch.js';
import { useUserContext } from "../../contexts/UserContext.jsx";
import Loading from '../../components/Loading.jsx';
import { useEffect, useState } from 'react';
import posthog from 'posthog-js';

export default function DirtyHandsMerch() {
  const { currentOrganization, currentPermissions } = useUserContext();
  const navigate = useNavigate();
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeLoading, setIframeLoading] = useState(true);

  useEffect(() => {
    posthog.capture('$pageview');
  }, []);

  useEffect(() => {
    const fetchEmbeddedUrl = async () => {
      const url = await getEmbeddedAnalyticsUrl(currentOrganization, 612);
      setIframeUrl(url);
      if (!url) {
        navigate('/404');
      }
    };

    if (currentOrganization && currentPermissions.includes('embed__612__view')) {
      fetchEmbeddedUrl();
    } else if (currentOrganization && !currentPermissions.includes('embed__612__view')) {
      navigate('/home');
    }
  }, [currentOrganization]);

  return (
    <>
      {!iframeUrl ? <Loading /> :
        <>
          {iframeLoading ? <Loading /> : null}
          <iframe
            src={iframeUrl}
            width="100%"
            style={{ height: '92vh', display: iframeLoading ? 'none' : 'block' }}
            onLoad={() => setIframeLoading(false)}
          ></iframe>
        </>
      }
    </>
  );
}

