import { Fragment, useCallback, useState, useEffect } from 'react';
import { Disclosure, Popover, PopoverButton, PopoverPanel, Menu, Transition } from '@headlessui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronDownIcon, Bars3Icon, XMarkIcon, UserCircleIcon, QuestionMarkCircleIcon, ChartPieIcon } from '@heroicons/react/24/outline';
import Muffin from '../assets/primary_logo.png';
import { useUserContext } from '../contexts/UserContext.jsx';
import { supabase } from '../libs/api.js';
import OrgSelector from './OrgSelector.jsx';
import Intercom, { shutdown } from "@intercom/messenger-js-sdk";
import { datadogRum } from '@datadog/browser-rum';
import posthog from 'posthog-js'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const INTERCOM_APP_ID = 'sqmzduox';

export default function Header() {
  const userData = useUserContext();
  const { profile, allowedNavigation: navigation, user } = userData;
  const navigate = useNavigate();
  const [bannerVisible, setBannerVisible] = useState(false);

  useEffect(() => {
    if (user) {
      posthog.identify(
        user.user.id,
        { name: user.user.user_metadata.first_name, email: user.user.identities[0].email }
      );
    }
  }, [user]);

  const signOut = useCallback(async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error('Sign out error:', error.message);
    posthog.reset();
    shutdown();
    navigate('/');
  }, []);

  if (profile && userData.user && userData.user.user) {
    Intercom({
      app_id: INTERCOM_APP_ID,
      name: profile.fullName,
      created_at: userData.user.user.created_at,
      email: userData.user.user.email,
      custom_launcher_selector:'#support',
      hide_default_launcher: true,
      user_hash: profile.hash
    });
    datadogRum.setUser({
      id: profile.id,
      name: profile.first_name
    });

    posthog.init('phc_R1QcWAF3bNUz6BvaoIMRtYD7DryN5hUayWZmTxQlGOj',
      {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only'
      }
    );
  }

  const location = useLocation();
  const dismissBanner = () => setBannerVisible(false);

  return (
    <>
      <div>
        {bannerVisible && (
          <div className="bg-yellow-100 border-b border-yellow-400 text-yellow-800 px-4 py-2 flex items-center justify-between">
            <div>
              Data from service xyz is delayed, for more details{' '}
              <a href="#" className="underline font-medium text-yellow-800 hover:text-yellow-900">
                click here
              </a>
            </div>
            <button onClick={dismissBanner} className="ml-4 flex-shrink-0 rounded-md bg-yellow-100 p-1 text-yellow-800 hover:text-yellow-900">
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Dismiss</span>
            </button>
          </div>
        )}

        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="flex flex-shrink-0 items-center">
                      <img className="block h-8 w-auto lg:hidden" src={Muffin} alt="Muffin Data" />
                      <img className="hidden h-8 w-auto lg:block" src={Muffin} alt="Muffin Data" />
                    </div>
                    <div className="hidden sm:-my-px sm:flex sm:space-x-5">
                      {navigation.map((item) =>
                        item.name === 'Dirty Hands' && item?.subNavigation && item?.subNavigation.length > 0 ? (
                          <Popover className="ml-8 border-transparent text-gray-500 hover:text-gray-700 inline-flex items-center pt-1 text-sm font-medium cursor-pointer" key={item.name}>
                            <PopoverButton className="inline-flex items-center pb-[0.1rem] outline-transparent">
                              <span>{item.name}</span>
                              <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
                            </PopoverButton>

                            <PopoverPanel
                              transition
                              className="absolute left-[21rem] top-[2.5rem] z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                              {({ close }) => (
                                <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm shadow-lg ring-1 ring-gray-900/5">
                                  <div className="p-4">
                                    {item.subNavigation.map((subItem) => (
                                      <div key={subItem.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                        { subItem.image? <div className="mt-1 flex h-[4rem] w-[4rem] flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                          <img className="mt-2 object-scale-up w-[60rem] pt-4 pb-4" src={subItem.image} />
                                        </div>: (
                                            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                              <ChartPieIcon aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-green-700" />
                                            </div>
                                          )}
                                        <div>
                                          <div className="font-semibold text-gray-900" onClick={() => {
                                            navigate(subItem.href);
                                            close();
                                          }}>
                                            {subItem.name}
                                            <span className="absolute inset-0" />
                                          </div>
                                          <p className="mt-1 text-gray-600">{subItem.description}</p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </PopoverPanel>
                          </Popover>
                        ) : (
                            <a
                              key={item.name}
                              onClick={() => navigate(item.href)}
                              className={classNames(
                                item.href === location.pathname
                                  ? 'border-primaryBrandColor text-gray-900'
                                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'inline-flex items-center border-b-2 pt-1 text-sm font-medium cursor-pointer ml-8'
                              )}
                              aria-current={item.href === location.pathname ? 'page' : undefined}
                            >
                              {item.name}
                            </a>
                          )
                      )}
                      <a
                        key="docs"
                        onClick={() => window.open("https://docs.muffindata.com", '_blank').focus()}
                        className={classNames(
                          'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'inline-flex items-center border-b-2 pt-1 text-sm font-medium cursor-pointer ml-8'
                        )}
                      >
                        {"Docs"}
                      </a>
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    <div className="flex flex-col">
                      <OrgSelector />
                    </div>
                    <div className="flex flex-col items-center justify-center ml-1 mr-1">
                      <QuestionMarkCircleIcon className="h-7 w-7 text-gray-700 hover:text-gray-400 cursor-pointer ring-black" id="support" />
                    </div>
                    <Menu as="div" className="relative">
                      <div>
                        <Menu.Button className="relative flex max-w-xs items-center text-gray-450 hover:text-gray-500 rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-offset-2">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <UserCircleIcon className="h-7 w-7" aria-hidden="true" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => navigate('/settings')}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block w-full text-left px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Settings
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => navigate('/reset-password')}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block w-full text-left px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Reset Password
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={signOut}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block w-full text-left px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primaryBrandColor focus:ring-offset-2">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 pb-3 pt-2">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      onClick={() => navigate(item.href)}
                      className={classNames(
                        item.href === location.pathname
                          ? 'border-primaryBrandColor bg-green-50 text-green-700'
                          : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                        'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
                      )}
                      aria-current={item.href === location.pathname ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
