import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import * as Sentry from "@sentry/react";

// Contexts
import { CustomNotificationProvider } from './contexts/CustomNotificationContext.jsx';
import { UserProvider } from './contexts/UserContext.jsx';
import { OrganizationProvider } from './contexts/OrganizationContext.jsx';

// Components
import Loading from './components/Loading.jsx';
import Header from './components/Header.jsx';

// Libraries
import ProtectedRoute from './libs/ProtectedRoute.jsx';
import ExportsGuard from './libs/ExportsGuard.jsx';
import { supabase } from "./libs/api";
import { getProfile } from './libs/fetch.js';
import { sprig } from '@sprig-technologies/sprig-browser';

// Pages
import * as Pages from './pages';
import * as Destinations from './pages/destinations';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '92c99f70-4535-4756-a2e8-2ff35f9c7c29',
  clientToken: 'pub18fba6facb0d14ed51af41a86590e56d',
  site: 'us5.datadoghq.com',
  service: 'frontend',
  env: 'prod',
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

const env = import.meta.env;
const DEV_ENV = import.meta.env.DEV;

DEV_ENV === false ? (
  Sentry.init({
    dsn: "https://99918e2f2a4128f9cf3e19cae3dbd37b@o4506893748666368.ingest.us.sentry.io/4506893752729600",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/muffindata\.com/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })): null;

const router = createBrowserRouter([
  { path: "/", element: <Pages.Signin /> },
  { path: "/signin", element: <Pages.Signin /> },
  { path: "/forgot-password", element: <Pages.ForgotPassword /> },
  { path: "/error", element: <Pages.ErrorPage /> },
  { path: "/redirect", element: <Pages.Redirect /> },
  { path: "*", element: <Pages.NotFoundPage /> },
  {
    element: <ProtectedRoute />,
    children: [
      {
        element: <>
          <Header />
          <Outlet />
        </>,
        children: [
          {
            path: "/home",
            element: <Pages.Home />,
          },
          {
            path: "/analytics",
            element: <Pages.Analytics />,
          },
          {
            path: "/analytics/dirty-hands-merch",
            element: <Pages.DirtyHandsMerch />,
          },
          { 
            path: "/integrations",
            element: <Pages.Integrations />,
          },
          {
            path: "/integrations/:integrationId",
            element: <Pages.Integration />
          },
          {
            element: <ExportsGuard />,
            children: [
              {
                path: "/destinations",
                element: <Destinations.Destinations />,
              },
              {
                path: "/destinations/bigquery",
                element: <Destinations.BigQuery />,
              },
            ],
          },
          {
            path: "/reset-password",
            element: <Pages.ResetPassword />,
          },
          {
            path: "/set-password",
            element: <Pages.SetPassword />,
          },
          {
            path: "/settings",
            element: <Pages.Settings />,
          },
          {
            path: "/select-organization",
            element: <Pages.SelectOrganization />,
          },
        ],
      },

    ],
  },

]);

function Main() {
  const [session, setSession] = useState(false);
  const [profile, setProfile] = useState(null);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_OUT') {
        setSession(null);
        setProfile(null);
        setCurrentOrganization(null);
        window.sessionStorage.removeItem('org');
      } else if (session) {
        setSession(session);
      } else {
        setSession(null);
      }
    });

    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (session) {
      getProfile().then((profileData) => {
        setProfile(profileData);
        setCurrentOrganization(profileData.default_organization_id || profileData.primary_organization_id);
      });
    }
  }, [session]);

  return (
    <>
      { session === false ? <Loading />:
        (<UserProvider value={{ user: session, profile, currentOrganization, loading, setLoading, setCurrentOrganization }}>
          <OrganizationProvider value={{}}>
            <CustomNotificationProvider>
              <RouterProvider router={router} />
            </CustomNotificationProvider>
          </OrganizationProvider>
        </UserProvider>
        )
      }
    </>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Main />);
